.loginLayout {
  width: 343px;
  box-shadow: 0px 4px 52px rgba(188, 175, 222, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.previewArLoginLayout {
  width: 343px;
  border-radius: 4px;
}

.loginInnerContainer {
  justify-content: center;
  display: flex;
  padding: 0px;
  margin: 0px;
}

.loginContainer {
  height: 598px;
  min-height: 598px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
  color: #222222;
  border-radius: 4px;
}
.previewArLoginContainer {
  height: 498px;
  min-height: 498px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
  color: #222222;
}

.loginContainerEmbedded {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
  color: #222222;
  border-radius: 4px;
}

@media screen and (max-width: 800px) {
  .loginLayout {
    width: 100%;
  }

  .loginContainer {
    height: 100%;
    margin: 0px 16px 32px 16px;
    box-shadow: 0px 4px 52px rgba(188, 175, 222, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .loginContainerEmbedded {
    height: 100vh;
    box-shadow: 0px 4px 52px rgba(188, 175, 222, 0.4), 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.loginNumberInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 8px;
  padding: 10px;
  border: 0.5px solid #eae6f2;
  border-radius: 4px;
  background-color: #fcfcfc;
  font-weight: 400;
  font-size: 12px;
}

.loginNumberInput {
  width: 100%;
  height: 100%;
  margin: 0px 10px;
  color: #222222;
  background-color: #fcfcfc;
}

.loginNumberInput:focus {
  outline-width: 0;
}

.loginNumberInputPrefix {
  color: #a3a3a3;
}

.loginNumberInputClear:hover {
  cursor: pointer;
}

.loginDisclaimer {
  padding: 16px;
}

.loginDisclaimerTrust {
  background-color: #f5fff8;
  margin: 0px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
}

.loginPrivacyBranding {
  font-size: 14px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.loginPrivacyTagline {
  margin-left: 8px;
  color: #124418;
}

.loginPrivacyPolicyPages {
  text-decoration: underline;
  text-decoration-color: #124418;
}

.loginTNC {
  height: 48px;
  font-size: 10px;
  font-weight: 400;
  padding: 8px;
  text-align: center;
  color: #124418;
}

.descriptionSection {
  display: flex;
  height: 44px;
  background-color: #1f123b;
  padding-left: 16px;
}

.loginGenerateOtpContainer {
  margin: 24px 16px;
  min-width: 311px;
}

.loginGenerateOtpContainerHeadline {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}

.loginGenerateOtpInputTitle {
  font-size: 14px;
  margin-bottom: 8px;
}

.loginGenerateOtpButtonContainer {
  padding: 10px 0px;
  border-top: 1px solid #e5e5e5;
}

.verifyOtpContainer {
  min-width: 311px;
  margin: 24px 16px;
}

.verifyOtpBackButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #65bf73;
}

.verifyOtpBackButton:hover {
  cursor: pointer;
}

.verifyOtpButtonContainer {
  padding: 10px 0px;
  border-top: 1px solid #e5e5e5;
}

.verifyOtpInputContainer {
  margin-bottom: 32px;
}

.verifyOtpDisplayNumber {
  font-size: 14px;
  margin-bottom: 8px;
}

.regenerateOtpButtonContainer {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.regenerateOtpButtonContainer:hover {
  cursor: pointer;
}

.regenerateOtpButtonText {
  margin-left: 8px;
}

.otpCounter {
  font-size: 14px;
  font-weight: 400;
  color: #ea4335;
}
